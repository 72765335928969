import axios from "axios";
import handleApiCall, {
  AuthHeader,
  AuthHeaderWithFormData,
} from "../handleApi";

const HOST = process.env.REACT_APP_HOST;
const Cookies = require("js-cookie");
const empId = Cookies.get("emp_id");

export const GlobalApi = {
  getAllSearch: (data, onSuccess, onError) => {
    return handleApiCall(
      axios.get(
        data &&
          `${HOST}/groups/general/search?search=${data}`,
        AuthHeader
      ),
      onSuccess,
      onError
    );
  },
};
