import { configureStore } from '@reduxjs/toolkit';

import sidebarCollapseReducer from '../reducers/SidebarCollapse';
import loggedInUserReducer from '../reducers/User';

export default configureStore({
    reducer: {
        sidebarCollapse: sidebarCollapseReducer,
        loggedInUser: loggedInUserReducer
    }
});