import React from "react";
import GlobalSearchGroup from "../GlobalSearchGroup";
import GlobalSearchSpace from "../GlobalSearchGroup/GlobalSearchSpace";
import GlobalSearchLinks from "../GlobalSearchLinks";
import "./GlobalSearchContainer.scss";
import img from "../../Assets/not-found.svg";

const GlobalSearchContainer = ({
  groupData,
  spaceData,
  links,
  setIsSearchResult,
  setInputText,
  isSearchResultFound,
}) => {
  // console.log(groupData, spaceData, links);
  return (
    <>
      {isSearchResultFound ? (
        <div
          className="p-3 shadow-md rounded bg-white h-full GlobalSearchContainer"
          style={{
            marginTop: "20px",
            marginLeft: "35px",
            marginRight: "35px",
            marginBottom: "20px",
          }}
        >
          {groupData && (
            <GlobalSearchGroup
              groupData={groupData}
              setIsSearchResult={setIsSearchResult}
              setInputText={setInputText}
            />
          )}
          {spaceData && (
            <div className={groupData ? "pt-6":""}>
              <GlobalSearchSpace
                spaceData={spaceData}
                setIsSearchResult={setIsSearchResult}
                setInputText={setInputText}
              />
            </div>
          )}
          {links && (
            <div className={groupData || spaceData ? "pt-6":""}>
              <GlobalSearchLinks
                links={links}
                setIsSearchResult={setIsSearchResult}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-full">
          <img src={img} alt="not found" className="not-found-img" />
        </div>
      )}
    </>
  );
};

export default GlobalSearchContainer;
