import { Button, Card, Typography, Row, Col } from "antd";
import React from "react";
import { EditOutlined, AlignLeftOutlined } from "@ant-design/icons";
import moment from "moment";
const { Paragraph } = Typography;

const GlobalSearchLinks = ({ links,setIsSearchResult }) => {
  // console.log(links, "links");
  return (
    <div>
      <Row className="mb-3 " gutter={[24, 24]}>
        <Col
          xs={24}
          md={24}
          className="fs-20 text-bolder mb-1"
          data-testid="product-title"
        >
          <span className="mr-2">
          <AlignLeftOutlined />
          </span>
          <span className="">Links</span>
        </Col>
        {links?.data?.map((linkData) => {
          return (
            <Col xs={24}>
              <Card
                bordered={false}
                className="linkCardBox"
                style={{
                  boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                }}
              >
                <div className="heading">
                  <div className="title">
                    <div className="fs-16 text-bolder p-0 m-0 text-capitalize">
                      {linkData?.title}
                    </div>
                  </div>
                </div>
                {/* <div className="description pt-2 fs-14">{linkData?.description}</div> */}
                <div className="description pt-2 fs-14">
                {linkData?.description}
                </div>
                <div className="description pt-2 fs-14">
                <a href={linkData?.link} target="_blank" rel="noreferrer">
                  <Paragraph copyable style={{ color: "#335fff" }}>
                    {linkData?.link}
                  </Paragraph>
                  </a>
                </div>
                <div className="link fs-12 d-md-flex text-center justify-between">
                  {/* <div className="text-gray text-capitalize">
                    - Author
                    <a
                  href={`/employeeDetails?key=${linkData?.user?.id}`}
                  className="text-gray"
                >
                    &nbsp;{linkData?.user?.name}
                    Name
                    </a>
                  </div> */}
                  <div className="text-gray">
                    Created at {moment(linkData?.created_at).format("Do MMM YYYY")}
                
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default GlobalSearchLinks;
