import React from "react";
import "./SideBar.scss";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../../Assets/dummy-logo.png";
import logoOneByOne from "../../Assets/logo11.png";

import {
  DesktopOutlined,
  RightOutlined,
  LeftOutlined,
  UserOutlined,
  AppstoreOutlined,
  TagOutlined,
  AlignLeftOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu, Tooltip, theme } from "antd";
import { toggleSidebar } from "../../reducers/SidebarCollapse";
import { useNavigate } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ setIsSearchResult, setInputText, handleClear }) => {
  const navigate = useNavigate();

  const Cookies = require("js-cookie");
  const role_id = Cookies.get("role_id");

  const location = useLocation();
  const dispatch = useDispatch();

  let innerWidth = window.innerWidth;

  const onClickLogo = () => {
    navigate("/");
  };

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { isCollapse } = useSelector((state) => state.sidebarCollapse);

  const items = [
    {
      key: "/",
      route: "/",
      icon: <DesktopOutlined />,
      label: "Dashboard",
      isSubmenu: false,
      access: true,
    },
    {
      key: "/all-links",
      route: "/all-links",
      icon: <AlignLeftOutlined />,
      label: "All Links",
      isSubmenu: false,
      access: true,
    },
    {
      key: "/quick-links",
      route: "/quick-links",
      icon: <LinkOutlined />,
      label: "Quick Links",
      isSubmenu: false,
      access: true,
    },
    {
      key: "/employee",
      route: "/employee",
      icon: <UserOutlined />,
      label: "Employee",
      isSubmenu: false,
      access: role_id === "1" || role_id === "3",
    },
    {
      key: "/department",
      route: "/department",
      icon: <TagOutlined />,
      label: "Department",
      isSubmenu: false,
      access: role_id === "1",
    },
    {
      key: "/group",
      route: "/group",
      icon: <AppstoreOutlined />,
      label: "Group",
      isSubmenu: false,
      access: true,
    },
  ].filter((i) => i.access === true);

  // const onClickNavlink = () => {
  //   if (innerWidth < 480) {
  //     dispatch(toggleSidebar(false));
  //   }
  // };
  const handleGlobalSearch = () => {
    setIsSearchResult(true);
    setInputText("");
    handleClear();
  };
  return (
    <Sider
      trigger={null}
      collapsible
      width={250}
      collapsed={isCollapse}
      className="h-full main-side-bar"
      data-testid="main-sideBar-id"
    >
      <div
        className={isCollapse ? `logoBoxContainerOneByOne` : `logoBoxContainer`}
        onClick={onClickLogo}
      >
        <div className="logoBox">
          <img
            src={isCollapse ? logoOneByOne : logo}
            alt="logo"
            data-testid="image-logo-id"
          />
        </div>
      </div>
      <div className="sideBarMenuList">
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          // items={items}
          className="pb-4 text-black"
        >
          {items.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {item?.isSubmenu === false ? (
                  <Menu.Item key={item?.key}>
                    {isCollapse ? (
                      <Tooltip title={item?.label} placement="right" color="geekblue" className="md:ml-2">
                        <NavLink to={item?.route} onClick={handleGlobalSearch}>
                          <span
                            className={`pr-md-2 ${
                              location.pathname === "/employee/add" ||
                              location.pathname.slice(0, 7) === "/group/"
                                ? "label-active"
                                : ""
                            }`}
                          >
                            {item?.icon}
                          </span>
                          {!isCollapse && (
                            <span
                              className={`label-name ${
                                location.pathname === "/employee/add" ||
                                location.pathname.slice(0, 7) === "/group/"
                                  ? "label-active"
                                  : ""
                              }`}
                            >
                              {item?.label}
                            </span>
                          )}
                        </NavLink>
                      </Tooltip>
                    ) : (
                      <NavLink to={item?.route} onClick={handleGlobalSearch}>
                        <span
                          className={`pr-1 pr-md-2 ${
                            location.pathname === "/employee/add" ||
                            location.pathname.slice(0, 7) === "/group/"
                              ? "label-active"
                              : ""
                          }`}
                        >
                          {item?.icon}
                        </span>
                        {!isCollapse && (
                          <span
                            className={`label-name ${
                              location.pathname === "/employee/add" ||
                              location.pathname.slice(0, 7) === "/group/"
                                ? "label-active"
                                : ""
                            }`}
                          >
                            {item?.label}
                          </span>
                        )}
                      </NavLink>
                    )}
                  </Menu.Item>
                ) : null}
                {item?.isSubmenu ? (
                  <SubMenu
                    title={
                      <>
                        {item?.icon}
                        <span className="">{item?.label}</span>
                      </>
                    }
                    className="siderSubMenu"
                  >
                    {item?.SubMenu.map((subItem) => {
                      return (
                        <Menu.Item key={subItem?.key}>
                          <NavLink to={subItem?.route}>
                            <span>{subItem?.label}</span>
                          </NavLink>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                ) : null}
              </React.Fragment>
            );
          })}
        </Menu>
      </div>
      <div
        className="sider-toggler-main cursor-pointer"
        data-testid="main-collapse-div-id"
        style={{ backgroundColor: `${colorPrimary}` }}
        onClick={() => dispatch(toggleSidebar(!isCollapse))}
      >
        {React.createElement(isCollapse ? RightOutlined : LeftOutlined, {
          className: "trigger",
          // onClick: () => dispatch(toggleSidebar(!isCollapse)),
          style: { fontSize: "16px", color: "#fff" },
        })}
      </div>
    </Sider>
  );
};

export default SideBar;
