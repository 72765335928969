// import { HOST } from "../../constants/api";
import axios from "axios";
import handleApiCall, { AuthHeader } from "../handleApi";

const Cookies = require("js-cookie");
const authToken = Cookies.get("authToken");
const empId = Cookies.get("emp_id");

// console.log('auth api authToken', authToken);

const HOST = process.env.REACT_APP_HOST;

export const AuthUserApi = {
  signIn: (data, onSuccess, onError) => {
    return handleApiCall(
      axios.post(`${HOST}/users/login`, data),
      onSuccess,
      onError
    );
  },
  changePasword: (data, onSuccess, onError) => {
    return handleApiCall(
      axios.put(`${HOST}/users/change-password/${empId}`, data, AuthHeader),
      onSuccess,
      onError
    );
  },
  forgotPassword: (data, onSuccess, onError) => {
    return handleApiCall(
      axios.post(`${HOST}/users/forgot-password`, data),
      onSuccess,
      onError
    );
  },
  verifyToken: (data, onSuccess, onError) => {
    return handleApiCall(
      axios.post(
        `${HOST}/users/verify-token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${data?.token}`,
          },
        }
      ),
      onSuccess,
      onError
    );
  },
  resetPassword: (data, onSuccess, onError) => {
    return handleApiCall(
      axios.put(
        `${HOST}/users/reset/password`,
        data?.payload,
        {
          headers: {
            Authorization: `Bearer ${data?.token}`,
          },
        }
      ),
      onSuccess,
      onError
    );
  },
  logout: (onSuccess, onError) => {
    return handleApiCall(
      axios.post(`${HOST}/users/logout`, {}, AuthHeader),
      onSuccess,
      onError
    );
  },
  
};
