import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
// import './scss/global.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './utils/Store';
import { ConfigProvider } from 'antd';
import { antConfig } from './utils/ant-config';
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={antConfig}
      >
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </ConfigProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
