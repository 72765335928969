import { Routes, Route, Navigate } from "react-router-dom";
import { PublicRoutes, PrivateRoutes } from "./Routes/index";
import { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";
import LayoutBox from "./pages/LayoutBox";
import SkeletonBoard from "./components/SkeletonBoard";

// import jwt from 'jsonwebtoken';

const Cookies = require("js-cookie");

function App() {
  const authToken = Cookies.get("authToken");
  const isLogin = Cookies.get("isLogin");

  // console.log('app : authToken',authToken);
  // console.log('app : isLogin',isLogin);
  // const decoded = jwt.verify(authToken, process.env.REACT_APP_JWT_SECERET);

  // let check = isLogin === '1'

  let location = useLocation();
  let pathname = location?.pathname?.split("/")[1];
  let title = pathname
    ? pathname.charAt(0).toUpperCase() + pathname.slice(1)
    : "";

  useEffect(() => {
    document.title = title ? title : "Dashboard";
  }, [title]);

  return (
    <Routes>
      {PublicRoutes.map(({ path, exact, component: Component }) => {
        return authToken && isLogin === "1" ? (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={<Navigate to="/" />}
          />
        ) : (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={
              <Suspense
                fallback={
                  <SkeletonBoard className="p-3 shadow-md rounded bg-white h-100 d-flex align-items-center justify-content-center" />
                }
              >
                <Component></Component>
              </Suspense>
            }
          />
        );
      })}
      <Route path="/" exact element={<LayoutBox />}>
        {PrivateRoutes.map(({ path, exact, component: Component }) => {
          return authToken && isLogin === "1" ? (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={<Component></Component>}
            />
          ) : (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={<Navigate to="/signin" />}
            />
          );
        })}
      </Route>
    </Routes>
  );
}

export default App;